.popup {
  z-index: 1000;
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.submitbutton {
  display: block;
  background-color: rgb(62, 40, 232);
  color: white;
  width: 97%;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.closebutton {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 18px;
  cursor: pointer;
}


.popupinner {
  display: block;
  position: relative;
  background-color: white;
  padding: 20px;
  text-align: center;
  outline-width: 3px;
  outline-color: black;
  border-color: black;
}


.depositinput {
  margin: 4px;
  padding: 0px;
  padding-top: 3px;
  padding-bottom: 3px;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: start;
  border-radius: 5px!important;
}

.depositError {
  color: red;
  font-size: 14px;
  margin: 0px;
  padding: 0px;
  right: 25px;
  position: absolute;
}
#err{
  border-color: red;
}
.warningtext {
  margin: 3px;
  color: black;
}

#label {
  margin: 10px 60px 40px 60px;
  font-family: Arial, Helvetica, sans-serif;
  font-size: x-large;
  font-weight: 800;
  font-stretch: extra-condensed;
}

#warning {
  text-align: start;
  padding: 5px;
  background-color: #EFEFEE;
  border-left-width: 2px;
  border-left-color: #E05E3D;
  border-left-style: solid;
  margin-bottom: 30px;
  max-width: 400px;
}
@media only screen and (max-width:600px){
  .warningtext{
    font-size: smaller;
  }
  #label{
    font-size:medium;
  }
}