.cryptoicons{
  margin: 20px;
    background-color:transparent;
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
.icon{
  width:9vw;
  max-width: 50px;
  margin:0px 20px;
}

  @media only screen and (max-width: 650px) { 
    .cryptoicons {
      margin: 20px 10px;
      padding: 0;
      display: flex;
    }.icon{
      margin:0px 5px;
    }
  }