.popup {
  z-index: 10000;
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: end;
}

.submitbutton {
  border-radius: 50px;
  display: block;
  background-color: rgb(62, 40, 232);
  color: white;
  width: 97%;
  border: none;
}

.closebutton {
  margin: 0;
  padding: 0;
  z-index: 1;
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 18px;
  cursor: pointer;
}
.popupTop{
  border-radius: 5px 5px 0px 0px;
  padding: 0px 15px 0px 15px;
  text-align: start;
  position: relative;
  background-color: white  ;
  height:60%
}
.popupBottom{
  border-radius: 0px 0px 5px 5px;
  padding: 0px 15px 25px 15px;
  text-align: start;
  background-color: white;
}

.popupinner {
  width: 30%;
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  position: relative;
  background-color: transparent;
  padding: 20px;
  text-align: center;
  outline-width: 3px;
  outline-color: black;
  border-color: black;
}
#wonImg{
  text-align: center;
  position: relative;
  object-fit: contain;
  height: 70%;
}
#description{
  color: black;
  font-size: 2vh;
}
#probability{
  color: rgba(0, 0, 0, 0.3);
  margin: 3% 6%;
  font-size: 1vh;
  font-stretch: extra-expanded;
}
#price{
  background-color: #FFC658;
  padding: 1.5% 3%;
  margin: 3%;
  font-size: 2vh;
  border-radius: 10px;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 600;
  width: fit-content;
}
#name{
  font-family: Arial, Helvetica, sans-serif;
  font-size: 2vw;
  font-weight: 600;
  margin: 1%;
  color: black;
}
@media only screen and (max-aspect-ratio:3/4) {
  .popup{
    justify-content: center;
  }
  .popupinner{
    width: 100%;
  }
  #name{
    font-size: 20px;
  }
}